import { BiFullscreen, BiXCircle } from "react-icons/bi";
import { useEffect, useState } from "react";
import { adminOrderService } from "../../../_services";

const OrderItems = (props) => {
    let order_id = props.order_id;
    const { formData, setFormData } = props;
    const [orderItemsData, setOrderItemsData] = useState([]);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminOrderService.getOrderItems(order_id);
                setOrderItemsData(data);
            } catch (error) {
                console.error("Error fetching orders data:", error);
            }
        };
        fetchData();
    }, [order_id]);

    const previewImage = (url) => {
        setPreviewImageUrl(url);
    };

    const closePreview = () => {
        setPreviewImageUrl(null);
    };

    const handleSizeChange = (itemId, newSize) => {
        const updatedOrderItems = orderItemsData.map(item => {
            if (item.id === itemId) {
                return { ...item, item_size: newSize };
            }
            return item;
        });
        setOrderItemsData(updatedOrderItems);
    };

    const handleColorChange = (itemId, newColor) => {
        const updatedOrderItems = orderItemsData.map(item => {
            if (item.id === itemId) {
                return { ...item, item_color: newColor };
            }
            return item;
        });
        setOrderItemsData(updatedOrderItems);
    };

    const handlePriceChange = (itemId, newPrice) => {
        const updatedOrderItems = orderItemsData.map(item => {
            if (item.id === itemId) {
                return { ...item, item_price: newPrice };
            }
            return item;
        });
        setOrderItemsData(updatedOrderItems);
        let total = 0;
        let orderDeliveryPrice = props.orderDeliveryPrice;
        for (let i = 0; i < updatedOrderItems.length; i++) {
            if (updatedOrderItems[i].item_price > 0) {
                total += parseFloat(updatedOrderItems[i].item_price);
            }
        }
        let final_price = total + orderDeliveryPrice;
        props.setFinalTotal(final_price)
    };

    useEffect(() => {
        formData.delete("order_items");
        setFormData(prevFormData => {
            const updatedFormData = new FormData(); // Create a new FormData instance
            // Iterate over entries of prevFormData and append them to updatedFormData
            for (const [key, value] of prevFormData.entries()) {
                updatedFormData.append(key, value);
            }
            // Append updated order items to updatedFormData
            orderItemsData.forEach(orderItem => {
                updatedFormData.append("order_items", JSON.stringify(orderItem));
            });
            return updatedFormData; // Return updatedFormData
        });
    }, [orderItemsData, setFormData]);

    return (
        <div className="form-group imgs-hoder mb-3">
            <div className="row">
                {orderItemsData.length > 0 ? (
                    orderItemsData.map((orderItem) => (
                        <div className="col-6 col-xl-4 mb-3" key={orderItem.id}>
                            <div className="uploaded-img-box shadow rounded-3 bg-white">
                                <div className="img-holder position-relative">
                                    <img src={`https://ns536895.ip-139-99-130.net/thedss/images/products/${orderItem.item_image}`}
                                         alt={`Item Order Image ${orderItem.id}`}
                                         className="rounded-top-3 img_style"/>
                                    <div
                                        className="position-absolute top-0 start-0 ps-2 pt-1 text-white c-pointer">
                                        <BiFullscreen size="20"
                                                      className="text-white"
                                                      onClick={() => previewImage(`https://ns536895.ip-139-99-130.net/thedss/images/products/${orderItem.item_image}`)}/>
                                    </div>
                                </div>
                                <div className="img-inputs-holder mt-1 pb-1">
                                    <div className="d-flex px-1 mb-1">
                                        <div className="px-1">
                                            <label
                                                className="gray-text-color text-real-small label-text"
                                                htmlFor={`order_item_size_${orderItem.id}`}>
                                                المقاس :
                                            </label>
                                        </div>
                                        <div className="px-1 w-100">
                                            <input type="text"
                                                   readOnly={props.read_only}
                                                   value={orderItem.item_size}
                                                   onChange={(e) => handleSizeChange(orderItem.id, e.target.value)}
                                                   id={`order_item_size_${orderItem.id}`}
                                                   name={`order_item_size_${orderItem.id}`}
                                                   className='form-control form-control-sm'/>
                                        </div>
                                    </div>
                                    <div className="d-flex px-1 mb-1">
                                        <div className="px-1">
                                            <label
                                                className="gray-text-color text-real-small label-text"
                                                htmlFor={`order_item_color_${orderItem.id}`}>
                                                اللون :
                                            </label>
                                        </div>
                                        <div className="px-1 w-100">
                                            <input type="text"
                                                   value={orderItem.item_color}
                                                   readOnly={props.read_only}
                                                   onChange={(e) => handleColorChange(orderItem.id, e.target.value)}
                                                   id={`order_item_color_${orderItem.id}`}
                                                   name={`order_item_color_${orderItem.id}`}
                                                   className='form-control form-control-sm'/>
                                        </div>
                                    </div>
                                    <div className="d-flex px-1 mb-1">
                                        <div className="px-1">
                                            <label
                                                className="gray-text-color text-real-small label-text"
                                                htmlFor={`order_item_price_${orderItem.id}`}>
                                                الثمن :
                                            </label>
                                        </div>
                                        <div className="px-1 w-100">
                                            <input type="text"
                                                   value={orderItem.item_price || 0}
                                                   readOnly={props.read_only}
                                                   onChange={(e) => handlePriceChange(orderItem.id, e.target.value)}
                                                   id={`order_item_price_${orderItem.id}`}
                                                   name={`order_item_price_${orderItem.id}`}
                                                   className='form-control form-control-sm'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
            {previewImageUrl && (
                <div className="previw-image bg-black">
                    <div
                        className="previw-image-content position-relative d-flex align-items-center justify-content-center">
                        <div className="position-absolute top-0 end-0 p-4">
                            <BiXCircle size="30" color="#fff"
                                       onClick={closePreview}
                                       className="c-pointer"
                            />
                        </div>
                        <img className=""
                             src={previewImageUrl}
                             alt="Preview"/>
                    </div>
                </div>
            )}
        </div>
    );
}

export default OrderItems;
